var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swipe_card" },
    [
      _c("IntersectionOberverLoading", {
        staticStyle: { top: "0", height: "0px" },
        attrs: { defaultShowServer: true },
        on: { onScroll: _vm.handleIntersection }
      }),
      _c("div", { staticClass: "card_title" }, [
        _c("div", { staticClass: "img_wrap" }, [
          _c("img", { attrs: { src: _vm.background, alt: "" } })
        ])
      ]),
      _c("div", { staticClass: "swipe_wrap" }, [
        _c("div", { staticClass: "swipe_card_list" }, [
          _c(
            "div",
            {
              staticClass: "prev",
              on: {
                click: function() {
                  return _vm.handleSwipe("prev")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: _vm.getImg("search", "left"), alt: "" }
              })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "next",
              on: {
                click: function() {
                  return _vm.handleSwipe("next")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: _vm.getImg("search", "right"), alt: "" }
              })
            ]
          ),
          _c("div", { staticClass: "swiper-container swipe" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: {
                      "swiper-slide": true,
                      last: index + 1 == _vm.list.length
                    }
                  },
                  [
                    !item.isAct
                      ? _c("goods-card", {
                          key: item.id,
                          attrs: { data: item }
                        })
                      : _c("goods-card-activity", {
                          key: item.id,
                          attrs: { data: item }
                        })
                  ],
                  1
                )
              }),
              0
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }