<template>
  <div class="swipe_card">
    <IntersectionOberverLoading
      :defaultShowServer="true"
      @onScroll="handleIntersection"
      style="top: 0; height: 0px"
    />
    <div class="card_title">
      <div class="img_wrap">
        <img :src="background" alt="" />
      </div>

      <!-- <span>{{ title }}</span> -->
    </div>
    <div class="swipe_wrap">
      <div class="swipe_card_list">
        <div class="prev" @click="() => handleSwipe('prev')">
          <img :src="getImg('search', 'left')" alt="" />
        </div>
        <div class="next" @click="() => handleSwipe('next')">
          <img :src="getImg('search', 'right')" alt="" />
        </div>
        <div class="swiper-container swipe">
          <!-- <div v-if="actList.length" class="swiper-wrapper">
            <div
              :class="{
                'swiper-slide': true,
                last: index + 1 == actList.length,
              }"
              v-for="(item, index) in actList"
              :key="index"
            >
              <goods-card-activity :data="item" :key="item.id" />
            </div>
          </div> -->
          <div class="swiper-wrapper">
            <div
              :class="{
                'swiper-slide': true,
                last: index + 1 == list.length,
              }"
              v-for="(item, index) in list"
              :key="index"
            >
              <goods-card v-if="!item.isAct" :data="item" :key="item.id" />
              <goods-card-activity v-else :data="item" :key="item.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
import goodsCard from "../components/goodsCard";
import goodsCardActivity from "../components/goodsCardActivity.vue"
import IntersectionOberverLoading from "@/components/IntersectionOberverLoading/index.vue";
import request from "@/utils/request";
import {mapGetters} from "vuex"
import qs from "qs";

export default {
  components: {
    goodsCard,
    IntersectionOberverLoading,
    goodsCardActivity
  },
  props: {
    config: Object,
    title: String,
    background: String,
  },
  data() {
    return {
      list: [],
      mySwiper: null,
      load: true,
      page: {
        pageId: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    ...mapGetters(['marketGroupConfig'])
  },
  methods: {
    init() {
      importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
        () => {
          this.mySwiper = new Swiper(".swipe", {
            slidesPerView: "auto",
            observer: true,
            // observeSlideChildren: true,
            on: {
              observerUpdate: (e) => {
                this.mySwiper.slideTo(0);
              },
            },
          });
        }
      );
    },
    getList() {
      if (this.config?.url) {
        let params = {
          ...this.page,
        };
        if (this.config.type == 2) {
          let arr = this.config.url.split("?");
          let domain = arr[0];
          let obj = qs.parse(arr[1]);
          request(domain, {
            method: "GET",
            isOpenApi: true,
            data: {
              ...params,
              ...obj,
            },
          }).then((res) => {
            if (res && res.code === 0) {
              this.list = res.data.list.slice(0, 10);
              this.load = true;
            }
          });
          return;
        } else {
          request(this.config.url, {
            method: "GET",
            data: {
              ...params,
            },
          }).then((res) => {
            if (res && res.code === 1) {
              this.list = res.data.list.slice(0, 10);
              this.load = true;
            }
          });
        }
      }
      return [];
    },
    setActivity(state){
      if(!state){
        return false;
      }
      const activity = Object.keys(state);
      let newData = this.list.map(item => {
        activity.forEach(a => {
          if (item.marketGroup[0]  == a && item.quanMLink > 0) {
          item.isAct = true
          return false
        } else {
          item.isAct = false
        }
        })
        
        return item
      })
      this.list = [...newData]
    },
    getImg(origin, name, type = "png") {
      return require(`../../../assets/${origin}/${name}.${type}`);
    },
    handleSwipe(type) {
      if (type === "prev") {
        this.mySwiper.slidePrev();
      }
      if (type === "next") {
        this.mySwiper.slideNext();
      }
    },
    handleIntersection() {
      if (!this.load) return;
      this.load = false;
      if (!this.list.length) {
        this.getList();
      }
    },
  },
  mounted() {
    this.init();
  },
  watch:{
    load(state){
      if(state){
        this.setActivity(this.marketGroupConfig)
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
.swipe_card {
  padding-top: 30px;
  .swipe_wrap:hover .swipe_card_list {
    .prev,
    .next {
      visibility: inherit;
    }
  }
  .card_title {
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    // min-width: 1200px;
    .img_wrap {
      width: 3000px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
    }
  }
  .swipe_card_list {
    width: 1032px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;

    .swiper-slide {
      width: 200px;
      margin-right: 8px;
    }
    .swiper-slide.last {
      margin-right: 0;
    }
    .prev {
      width: 30px;
      height: 60px;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 17.5px;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
      position: absolute;
      left: -55px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100;
      visibility: hidden;
      img {
        width: 9px;
        height: 15px;
        vertical-align: middle;
      }
      &::before {
        height: 100%;
        display: inline-block;
        content: "";
        vertical-align: middle;
      }
    }
    .next {
      width: 30px;
      height: 60px;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 17.5px;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
      position: absolute;
      right: -55px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100;
      visibility: hidden;
      img {
        width: 9px;
        height: 15px;
        vertical-align: middle;
      }
      &::before {
        height: 100%;
        display: inline-block;
        content: "";
        vertical-align: middle;
      }
    }
  }
}
</style>
